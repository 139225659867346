@import '../styles/global.css';
/* Import the global styles */

.landing-page-main {
  background:
    linear-gradient(rgba(255, 255, 255, 0.3),
      /* Adjust the alpha value for desired lightness */
      rgba(255, 255, 255, 0.3)),
    url('../assets/background-map.svg') no-repeat center center;
  background-size: cover;
}

.landing-page {
  padding-top: 10px;
  color: #333;
  position: relative;
  z-index: 0;
  /* Ensure it's below other elements */
  padding-bottom: 10px;
}

/* .header {
  background-color: #9F5FFE;
  padding: 0 80px;
  color: white;
} */

.header {
  position: fixed;
  /* Fixes the header at the top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* Ensures the header stays above other content */
  background-color: #9F5FFE;
  padding: 0 80px;
  color: white;
  border-bottom: 1px solid #ddd;
  /* Optional: Add a border */
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.header2 {
  margin-top: 50px;
  padding: 0 100px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.header-contact {
  display: flex;
}

.header-contact span {
  margin-right: 20px;
  font-size: 14px;
}

.nav a {
  margin: 0 10px;
}

.header-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header-bottom2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.logo {
  margin-top: 20px;
  display: flex;
  text-decoration: none;
}

.logo-text {
  margin-top: 0px;
  margin-left: 10px;
  font-size: 24.8px;
  font-weight: bold;
  color: #1D1D1D;
}

.main-nav {
  margin-left: 200px;
  padding-bottom: 10px;
}

.main-nav a {
  border: 1px solid white;
  padding: 5px 20px;
  border-radius: 10px;
  font-weight: bold;
  margin: 0 15px;
  color: white;
  text-decoration: none;
  font-size: 13px;
}

/* .nav-link {
  font-weight: bold;
  margin: 0 15px;
  color: #9F5FFE;
  text-decoration: none;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
} */

/* Active Link Styling */
.active-link {
  background-color: #9F5FFE;
  /* Lavender */
  color: white;
  border-radius: 10px;
  border: 1px solid white;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 16px;
  /* Body Medium */
}

.dropdown-icon {
  margin-left: 12px;
  margin-top: 5px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  min-width: 160px;
  margin-top: 10px;
  border-radius: 5px;
  z-index: 1;
}

.dropdown-menu li {
  list-style: none;
}

.dropdown-menu li a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
  /* Body Medium */
}

.dropdown-menu li a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.donate-button {
  margin-left: 40px !important;
  width: 200px;
  background-color: transparent !important;
  border: 2px solid #9F5FFE !important;
  color: #9F5FFE !important;
  font-weight: bold;
  padding: 12px 25px !important;
  border-radius: 5px;
  cursor: pointer;
}

.button-text {
  margin-right: 12px;
}

.primary-section {
  display: flex;
  justify-content: center;
  padding: 100px 10px;
  align-items: center;
  /* background-color: #f5f5f5; */
}

.main-section {
  margin-top: -80px;
  max-width: 1000px;
}

.main-section h1 {
  color: #1D1D1D;
  font-weight: bold;
  font-size: 36px;
  /* H1 size */
  margin-bottom: 20px;
  margin-top: 6px;
  line-height: 60px;
}

.main-section strong {
  color: #9F5FFE;
}

.main-section h1 span {
  color: #9F5FFE;
}

.main-section p {
  font-size: 14px;
  /* Body Large */
  margin-bottom: 40px;
  line-height: 24px;
  color: #555;
}

.get-started-button {
  background-color: #00c2c5;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  /* Body Medium */
}

.activity-button {
  margin-top: -10px;
  position: absolute;
  margin-left: 80px;
  border: none;
  cursor: pointer;
  background: transparent;

}

.campaign-section {
  position: relative;
  padding: 50px;
  z-index: 2;
  margin-left: 50px;
  display: flex;
  justify-content: center;
}

/* Campaign Card */
.campaign-card {
  padding: 50px;
  width: 500px;
  /* Set the desired width */
  position: relative;
  z-index: 3;
  /* Ensure the card is above the design border */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}


/* Design Border */
.design-border {
  position: absolute;
  z-index: 1;
  /* Place the design border just above the landing page background but below the card */
  right: 35px;
  bottom: 30px;
  border-radius: 15px;
  height: 400px;
  width: 250px;
  background-color: #1AD0D1;
}

.donate-heart {
  position: absolute;
  right: -30px;
  top: -30px;
}

.campaign-tag {
  background-color: #00c2c5;
  color: #ffffff;
  padding: 15px 25px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 15px;
  font-size: 14px;
  /* Body Medium */
  font-weight: bold;
}

.campaign-card h2 {
  margin: 10px 0;
  font-size: 40px;
  /* H2 size */
  font-weight: bold;
}

.campaign-card p {
  font-size: 18px;
  /* Body Large */
  margin-bottom: 20px;
  color: #777;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin-bottom: 10px;
}

.progress {
  height: 12px;
  background-color: #00c2c5;
  border-radius: 10px;
}

.campaign-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 16px;
  /* Body Medium */
  font-weight: bold;
}

.payment-buttons {
  display: flex;
  justify-content: space-between;
}

.payment-buttons button {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.google-pay-button img {
  width: 160px;
}

.apple-pay-button img {
  width: 160px;
}

.header-contact img {
  margin-right: 5px;
  width: 15px;
}

@media (max-width: 768px) {
  .landing-page {
    align-items: center;
    width: 80%;
  }

  .header,
  .header2 {
    padding: 0 10px;
  }

  .header-bottom {
    width: 100%;
  }

  .header-bottom2 {
    margin-top: -30px;
    width: 100%;
  }

  .header-contact {
    display: block;
  }

  .main-nav {
    margin-top: 10px;
    margin-left: 0px;
    padding-bottom: 0px;
  }

  .header-contact img {
    margin-right: 5px;
    width: 12px;
  }

  .header-contact span {
    font-size: 10px;
  }

  .nav img {
    height: 12px;
    width: 10px;
  }

  .logo-text {
    margin-left: 10px;
    font-size: 17px;
    font-weight: bold;
    color: #1D1D1D;
  }

  .main-nav a {
    font-weight: bold;
    margin: 0 5px;
    font-size: 10px;
    padding: 2px 5px;
  }

  .dropdown-menu li a {
    font-size: 10px;
  }

  .donate-button {
    margin-top: -3px !important;
    width: 90px !important;
    background-color: transparent !important;
    border: 2px solid #9F5FFE !important;
    color: #9F5FFE !important;
    font-weight: bold;
    padding: 5px 10px !important;
    font-size: 10px !important;
    border-radius: 5px !important;
    cursor: pointer;
  }

  .primary-section {
    width: 90%;
    display: block;
    padding: 60px 20px;
    align-items: center;
    /* background-color: #f5f5f5; */
  }

  .campaign-section {
    width: 300px;
    right: 0px;
    left: -60px;
    position: absolute;
  }

  /* Campaign Card */
  .campaign-card {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  /* Design Border */
  .design-border {
    display: none;
  }

  .google-pay-button img {
    width: 120px;
  }

  .apple-pay-button img {
    width: 120px;
  }

  .get-started-button {
    padding: 10px 20px;
  }

  .activity-button {
    margin-top: -10px;
    margin-left: 100px;

  }

  .donate-heart {
    position: absolute;
    right: 10px;
    top: 35px;
  }

  .main-section h1 {
    font-size: 26px;
    line-height: 40px;
  }

  .campaign-card h2 {
    font-size: 30px;
  }

  .campaign-card p {
    font-size: 14px;
  }

}