.need-help-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 4rem;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #333;
  }
  
  .need-help-title {
    color: #9F5FFE;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .faq-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin: 0.5rem 0 1rem;
    color: #333;
  }
  
  .faq-description {
    text-align: center;
    margin-bottom: 2rem;
    color: #777;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .accordion {
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .accordion-item {
    border-top: 1px solid #e0e0e0;
  }
  
  .accordion-item:first-child {
    border-top: none;
  }
  
  .accordion-header {
    padding: 1.5rem 2rem;
    cursor: pointer;
    background-color: #f9f9f9;
    font-weight: 600;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
  }
  
  .accordion-header.active {
    background-color: #54D4D4;
    color: white;
  }
  
  .accordion-header.active::after {
    color: #ffff;
    content: "-";
    font-size: 1.5rem;
  }
  
  .accordion-header::after {
    color: #54D4D4;
    content: "+";
    font-size: 1.5rem;
    transition: transform 0.3s;
  }
  
  .accordion-content {
    padding: 1rem 1.5rem;
    background-color: #f0f0f0;
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    display: none;
  }
  
  .accordion-content.active {
    display: block;
    background-color: #f8f8f8;
  }
  
  .accordion-header:hover {
    background-color: #e7e7e7;
  }
  
  @media (max-width: 768px) {

    .need-help-container{
      padding: 1rem;
    }

    .need-help-title {
      font-size: 1rem;
    }

    .faq-title {
      font-size: 1.2rem;
    }

    .faq-description {
      margin-bottom: 2rem;
    }

    .accordion-header {
      padding: 1rem 1.5rem;
    }

    .accordion-header {
      padding: 1rem 1.5rem;
      font-size: 0.8rem;
    }
  }