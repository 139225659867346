.footer-container {
  padding-top: 100px !important;
    background-color: #f9f9f9;
    padding: 2rem;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    margin: 1rem;
    min-width: 200px;
  }

  .footer-logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .footer-logo {
    width: 45px;
    height: 45px;
    margin-right: 0.8rem;
  }
  
  .footer-logo-text {
    font-size: 24.5px; /* Adjust size as needed */
    color: #1D1D1D;
    font-weight: 600;
  }
  
  
  .footer-section h4 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1rem;
  }

  .footer-section p {
    font-size: 16px;
    color: #6F7775;
  }
  
  
  .footer-section ul {
    list-style: none;
    padding: 0;
    line-height: 2;
  }

  .contact-info ul{
    line-height: 3;
  }

  .footer-section ul li {
    display: flex;
    align-items: flex-start; /* Align text at the top */
    color: #555;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .footer-section ul li div {
    margin-top: -12px;
    display: flex;
    flex-direction: column;
  }
  
  .footer-section ul li strong {
    font-size: 14px;
    color: #333;
  }
  
  .footer-section ul li p {
    margin-top: -20px;
    font-size: 12px;
    color: #777;
  }

  .footer-section ul li img {
    margin-right: 0.5rem;
    width: 34px;
  }

  .footer-socials {
    display: flex;
    gap: 1rem;
    margin-top: 3rem;
  }
  
  .footer-socials img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .footer-bottom {
    text-align: center;
    padding: 1rem;
    background-color: #a57fff;
    color: white;
    font-size: 0.9rem;
  }
  