@import '../styles/global.css';
/* Import the global styles */

.who-we-are-container {
  /* margin-top: 100px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 150px 150px;
    background-color: #fff;
  }
  
  .image-section {
    position: relative;
    max-width: 50%;
  }
  
  .main-image img {
    width: 100%;
    border-radius: 15px;
  }
  
  .overlay-image {
    position: absolute;
    bottom: -35px;
    right: -30px;
    width: 60%;
  }
  
  .overlay-image img {
    width: 80%;
    border-radius: 15px;
  }
  
  .icon {
    position: absolute;
    top: -20px;
    left: -20px;
    background-color: #30D5C8;
    padding: 15px;
    border-radius: 50%;
  }
  
  .icon img {
    width: 50px;
  }
  
  .text-section {
    margin-top: -90px;
    max-width: 50%;
    padding-left: 50px;
  }
  
  .subtitle {
    color: #9B51E0;
    font-weight: bold;
    font-size: 20px;
  }
  
  .title {
    font-size: 30px;
    color: #333;
  }
  
  .description {
    margin: 20px 0;
    color: #6F7775;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .benefits-list {
    font-weight: 600;
    list-style: none;
    padding: 0;
    margin: 20px 0;
    font-size: 16px;
    color: #1D1D1D;
  }
  
  .benefits-list li {
    margin-bottom: 10px;
  }

  .check-icon {
    background-color: #1AD0D1;
    margin-right: 10px;
    font-size: 10px;
    padding: 2px 5px;
    color: #ffffff;
    border-radius: 50%;
  }
  
  .about-us-button {
    background-color: #30D5C8;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .about-us-button:hover {
    background-color: #2BB0A6;
  }
  
  @media (max-width: 768px) {
    .who-we-are-container {
      margin-top: 0px;
      display: block;
      align-items: center;
      justify-content: center;
      padding: 20px 50px;
    }

    .text-section {
      margin-top: 50px;
      max-width: 100%;
      padding-left: 0px;
    }
    .image-section {
      max-width: 90%;
    }
    .title {
      font-size: 20px;
    }

    .description {
      font-size: 14px;
    }

    .benefits-list {
      font-size: 12px;
    }
  }