/* ContactUs.css */
.contact-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  background-color: white;
  padding: 40px;
}

.contact-form {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  width: 100%;
}

.contact-form h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 10px;
  color: #a269ff;
}

.contact-form p {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  outline: none;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #a269ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .contact-form {
    padding: 20px;
  }

  .contact-form h2 {
    font-size: 1.75rem;
  }

  .contact-form p {
    font-size: 0.9rem;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.95rem;
  }

  .submit-button {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .contact-form h2 {
    font-size: 1.5rem;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.85rem;
  }

  .submit-button {
    font-size: 0.85rem;
  }
}