@import '../styles/global.css';
/* Import the global styles */


.our-campaign-container {
    padding: 50px 150px;
    
  }

  .main-titles{
    margin-left: 60px;
    max-width: 770px;
  }
  
  .section-subtitle {
    color: #a269ff;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .section-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #1D1D1D;
  }
  
  .campaign-cards-tab {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .campaign-card-tab {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    text-align: left;
  }
  
  .campaign-image {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .campaign-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .campaign-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .raised-amount {
    color: #333;
  }
  
  .goal-amount {
    color: #b3b3b3;
  }
  
  .progress-bar {
    height: 6px;
    background: #e0e0e0;
    border-radius: 3px;
    margin-bottom: 15px;
    position: relative;
  }
  
  .progress {
    height: 100%;
    background-color: #30d5c8;
    border-radius: 3px;
  }
  
  .campaign-buttons {
  
    display: flex;
    justify-content: space-between;
  }
  
  .donate-button-tab,
  .detail-button {
    width: 200px;
    background-color: #30d5c8;
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .detail-button {
    width: 200px;
    background-color: transparent;
    color: #30d5c8;
    /* border: 2px solid #30d5c8; */
  }
  
  .donate-button-tab:hover,
  .detail-button:hover {
    opacity: 0.8;
  }
  
  @media (max-width: 768px) {
    .our-campaign-container {
      padding: 10px 50px;
      
    }
    .campaign-cards-tab {
      flex-direction: column;
      align-items: center;
    }
  
    .campaign-card-tab {
      max-width: 100%;
    }

    .main-titles{
      margin-left: 0px;
      width: 100%;
    }
    
    .section-subtitle {
      margin-top: 60px;
      color: #a269ff;
      font-size: 18px;
      margin-bottom: 10px;
    }
    
    .section-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 30px;
      color: #1D1D1D;
    }
    
  }
  