.action-section {
    padding: 50px 20px;
  }
  
  .action-content {
    text-align: center;
    align-items: center;
    /* margin-left: 120px; */
   
    background-color: #FAFAFA;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 60px;
 
    /* text-align: left; */
  }
  
  .action-text {
    padding: 0px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .action-subtitle {
    color: #8b53f6;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .action-title {
    font-weight: bold;
    font-size: 30px;
    line-height: 46px;
    color: #1D1D1D;
    margin-bottom: 20px;
    max-width: 600px;
  }
  
  .donate-button {
    text-decoration: none;
    background-color: #24d0d0;
    color: #ffffff;
    border: none;
    padding: 15px 25px;
    font-size: 18px;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .stats-section {
    align-items: center;
    height: 300px;
    background-color: #24d0d0;
    padding: 40px 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .stat-item {
    text-align: center;
    color: #ffffff;
    margin: 20px 0;
  }
  
  .stat-value {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 10px;
    text-decoration: underline;
  }
  
  .stat-label {
    font-size: 18px;
    color: #e2e8f0;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  
  @media (max-width: 768px) {
    .action-section {
      margin-top: 200px;
      padding: 30px 20px;
    }
    .action-content {
      text-align: center;
    }
  
    .donate-button {
      margin-top: 20px;
      width: 100%;
    }

    .action-content {
      margin-top: -200px;
      margin-left: 0px;
      position: absolute;
      background-color: #FAFAFA;
      border-radius: 15px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
      width: 80%;
      text-align: center;
      align-items: center;
    }
    
    .action-text {
      padding: 0px 0px;
      display: block;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
    }

    .stat-value {
      font-size: 38px;
    }

    .action-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: #1D1D1D;
      margin-bottom: 20px;
      width: 100%;
    }

      
  .stats-section {
    align-items: center;
    height: 100%;
    background-color: #24d0d0;
    padding: 40px 0;
    display: block;
    justify-content: center;
    flex-wrap: nowrap;
  }
    
  }
  