.team-container {
  text-align: center;
  padding: 40px;
}

.team-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #a269ff;
}

.team-member {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90% !important;
  padding: 5px;
  border: 1.5px solid #a269ff;;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

.team-member img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.team-member h3 {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: #24d0d0;
}

.team-member p {
  font-size: 1rem;
  color: #777;
}

.team-member:hover {
  transform: scale(1.05);
}

.slick-prev, .slick-next {
  font-size: 2rem;
  color: #a269ff;
}

@media (max-width: 768px) {
  .team-member:hover {
    transform: scale(1);
  }  

  .team-member {
    width: 95% !important;
  }
}
